import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-finances/shared/constants'
import { WorkOrderCreateDTO } from 'plugin-finances/shared/dto/WorkOrderCreateDTO'
import { WorkOrderResponseDTO } from 'plugin-finances/shared/dto/WorkOrderResponseDTO'
import { WorkOrderUpdateDTO } from 'plugin-finances/shared/dto/WorkOrderUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.WORK_ORDERS}`

export async function findWorkOrder(id: string): Promise<WorkOrderResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function listWorkOrders(params?: ListWorkOrdersFilters): Promise<PaginationDTO<WorkOrderResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createWorkOrder(data: WorkOrderCreateDTO): Promise<WorkOrderResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateWorkOrder(id: string, data: WorkOrderUpdateDTO): Promise<WorkOrderResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteWorkOrder(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
