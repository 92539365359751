import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const EditorFieldset = styled.fieldset`
  > .card.form-fields {
    > .card-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'CLT CLT CLT CLT'
        'IDF SRV VAL STS'
        'NWH NWH NWH NWH'
        'DTS DTS DTS DTS';
      gap: var(--spacing-2);

      > .form-select-container--client {
        grid-area: CLT;
      }

      > .form-select-container--service {
        grid-area: SRV;
      }

      > .form-input-container--value {
        grid-area: VAL;
      }

      > .form-select-container--status {
        grid-area: STS;
      }

      > .form-input-container--newHistory {
        grid-area: NWH;
      }

      > .form-textarea-container--details {
        grid-area: DTS;
      }
    }
  }

  > .sorted-table__wrapper {
    margin-top: var(--spacing-2);
  }
`

export const EditorContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    > .card.actions-footer {
      margin-top: var(--spacing-2);

      > .card-body {
        display: flex;
        justify-content: space-between;

        > .actions-area {
          display: flex;
          justify-content: center;
          align-items: center;

          > button {
            padding-top: 5px;
            padding-bottom: 5px;

            &.btn.btn-link {
              color: var(--gray-0);
              text-decoration: none;
            }
          }

          > span {
            margin-left: 10px;
          }
        }

        > .required-warn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--font-semibold);
          color: var(--red-500);
        }
      }
    }
  }
`
