import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect, HookFormTextarea } from 'methone/components/ui/Forms'
import { SortedTable } from 'methone/components/ui/SortedTable'
import { dateService } from 'methone/services/dateService'
import { i18n } from 'methone/services/i18n'
import { Numbers } from 'methone/shared/Numbers'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { listServices } from 'plugin-finances/services/financeServices'
import { createWorkOrder, findWorkOrder, updateWorkOrder } from 'plugin-finances/services/financeWorkOrders'
import { WorkOrderHistoryResponseDTO } from 'plugin-finances/shared/dto/WorkOrderHistoryResponseDTO'
import { WorkOrderResponseDTO } from 'plugin-finances/shared/dto/WorkOrderResponseDTO'
import { WorkOrderUpdateDTO } from 'plugin-finances/shared/dto/WorkOrderUpdateDTO'
import { MENU_AREA, MENU_PATHS, workOrderStatus } from 'plugin-finances/utils/constants'
import { servicesToOption } from 'plugin-finances/utils/toOption'

import { EditorContainer, EditorFieldset } from './styled'

// eslint-disable-next-line prettier/prettier
type Omits = 'clientId' | 'serviceId' | 'value' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'client' | 'service' | 'status'
interface FormProps extends Omit<WorkOrderResponseDTO, Omits> {
  value: string
  client: Option<string>
  service: Option<string>
  status: Option<string>
  newHistory: string
}

const defaultValues: FormProps = {
  id: '',
  client: null,
  service: null,
  identifier: '',
  value: '',
  status: null,
  details: '',
  newHistory: '',
  history: []
}

export function WorkOrdersEditorPage(): JSX.Element {
  const { id } = useParams()
  const { control, handleSubmit, reset, watch } = useForm<FormProps>({ defaultValues, mode: 'all' })
  const history = watch('history')

  const [savingStatus, setSavingStatus] = React.useState('idle')
  const { loading, run, data: workOrder, error } = useRequest(findWorkOrder, { manual: true })
  const { loading: servicesLoading, data: services, error: servicesError } = useRequest(listServices)
  const navigate = useNavigate()

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setSavingStatus('saving')

      data.history.push({ details: `${data.status.value} - ${data.newHistory}` } as WorkOrderHistoryResponseDTO)

      const bodyData: WorkOrderUpdateDTO = {
        clientId: data.client.value,
        serviceId: data.service.value,
        identifier: data.identifier,
        status: data.status.value,
        value: Numbers.parseFloat(data.value),
        details: data.details,
        history: data.history
      }

      if (id === 'new') {
        const createdWorkOrder = await createWorkOrder(bodyData)
        const detailsPrefix = window.Methone.lang === 'pt-BR' ? 'Numero da ordem de serviço' : 'Work order number'
        const params = new URLSearchParams({
          clientId: createdWorkOrder.clientId,
          details: `${detailsPrefix}: ${createdWorkOrder.identifier}`,
          serviceId: createdWorkOrder.serviceId,
          value: Numbers.formatString(createdWorkOrder.value)
        })
        navigate(`/${MENU_AREA}/${MENU_PATHS.CASHFLOW}/new?${params.toString()}`)
        // navigate(`/${MENU_AREA}/${MENU_PATHS.WORK_ORDERS}/${createdWorkOrder.id}`)
      } else {
        await updateWorkOrder(id, bodyData)
        run(id)
      }

      setSavingStatus('saved')
    } catch (e) {
      setSavingStatus('error')
      handleHTTPRequestError(e)
    }
  }

  React.useEffect(() => {
    if (workOrder) {
      reset({
        ...workOrder,
        value: Numbers.formatString(workOrder.value),
        newHistory: '',
        client: { value: workOrder.client.id, label: workOrder.client.name },
        service: { value: workOrder.service.id, label: workOrder.service.name },
        status: workOrderStatus.find((status) => status.value === workOrder.status)
      })
    }
  }, [workOrder]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    reset(defaultValues)
    if (id !== 'new') {
      run(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (servicesError) {
      handleHTTPRequestError(servicesError)
    }

    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error, servicesError])

  return (
    <>
      <FixedLoading position="absolute" enabled={savingStatus === 'saving' || loading || servicesLoading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card className="form-fields">
              <Card.Body>
                <ClientSelectorHookForm
                  control={control}
                  name="client"
                  rules={{ required: true }}
                  label={i18n('Client')}
                />
                <HookFormInput
                  control={control}
                  rules={{ required: true }}
                  name="identifier"
                  label={i18n('Identifier')}
                />
                <HookFormSelect
                  control={control}
                  rules={{ required: true }}
                  name="service"
                  label={i18n('Service')}
                  options={servicesToOption(services?.entries ?? [])}
                />
                <HookFormInput control={control} inputProps={{ type: 'number' }} name="value" label={i18n('Value')} />
                <HookFormSelect
                  control={control}
                  rules={{ required: true }}
                  name="status"
                  label={i18n('Status')}
                  options={workOrderStatus}
                />
                <HookFormInput
                  control={control}
                  rules={{ required: true, maxLength: 255 - 16 }}
                  name="newHistory"
                  label={i18n('New history entry')}
                />
                <HookFormTextarea control={control} name="details" label={i18n('Details')} />
              </Card.Body>
            </Card>
            {history && history.length > 0 && (
              <SortedTable
                columns={[
                  { columnRef: 'details', title: i18n('History') },
                  { columnRef: 'createdAt', title: i18n('Date'), width: 200 }
                ]}
                items={(history ?? [])
                  .map((historyItem) => {
                    const [status, ...rest] = historyItem.details.split(' - ')
                    const mappedStatus = workOrderStatus.find((s) => s.value === status.trim())?.label ?? status.trim()

                    return {
                      details: rest != null && rest.length > 0 ? `${mappedStatus} - ${rest.join(' - ')}` : status,
                      createdAt: dateService.formatDateTime(historyItem.createdAt)
                    }
                  })
                  .reverse()}
              />
            )}
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <Button type="submit" variant="primary">
                  {i18n(id === 'new' ? 'Create' : 'Save')}
                </Button>
                <span>{i18n('or')}</span>
                <Button
                  type="button"
                  variant="link"
                  onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.WORK_ORDERS}`)}
                >
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
