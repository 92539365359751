import { Container } from 'react-bootstrap'

import styled from 'styled-components'

function handleStatusColor(status: string): string {
  switch (status) {
    case 'UNDER_ANALYSIS':
      return 'var(--yellow-500)'
    case 'REPROVED':
      return 'var(--red-500)'
    case 'APPROVED':
      return 'var(--blue-500)'
    case 'FINISHED':
      return 'var(--green-500)'
    default:
      return 'var(--gray-300)'
  }
}

export const SolidDot = styled.div<{ status: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ status }) => handleStatusColor(status)};
`

export const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas:
    'RW1'
    'RW2'
    'ACT';
  grid-template-columns: 1fr;
  gap: var(--spacing-2);

  > div.form-row:nth-child(1) {
    grid-area: RW1;
    display: grid;
    grid-template-areas: 'CLT CGP';
    grid-template-columns: 1fr 200px;
    gap: var(--spacing-2);

    > .form-select-container--client {
      grid-area: CLT;
    }
  }

  > div.form-row:nth-child(2) {
    grid-area: RW2;
    display: grid;
    grid-template-areas: 'IDF SCT SRV STS FDT TDT';
    grid-template-columns: 150px 1fr 1fr 1fr 100px 100px;
    gap: var(--spacing-2);

    > .form-select-container--identifier {
      grid-area: IDF;
    }

    > .form-select-container--sector {
      grid-area: SCT;
    }

    > .form-select-container--service {
      grid-area: SRV;
    }

    > .form-select-container--status {
      grid-area: STS;
    }

    > .form-select-container--fromDate {
      grid-area: FDT;
    }

    > .form-select-container--toDate {
      grid-area: TDT;
    }
  }

  > .actions {
    grid-area: ACT;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      gap: var(--spacing-2);
    }
  }
`

export const WorkOrdersContainer = styled(Container)`
  padding: var(--spacing-4);

  .card {
    .card-body {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-2);

      > button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        gap: var(--spacing-2);

        > .badge {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }
  }

  > div {
    > table {
      .table-column-client {
        > div {
          display: flex;
          gap: var(--spacing-2);

          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;

            &.badge {
              height: 21px;
            }
          }
        }
      }
    }
  }
`
