export const ptBR: I18NProps = {
  name: 'Português (Brasil)',
  code: 'pt-BR',
  values: {
    Finance: 'Financeiro',
    Sectors: 'Setores',
    Services: 'Serviços',
    'Work orders': 'Ordens de serviço',
    Cash: 'Caixa',
    'Cash flow': 'Fluxo de caixa',
    Sector: 'Setor',
    Service: 'Serviço',
    Account: 'Conta',
    Identifier: 'Identificador',
    Date: 'Data',
    Value: 'Valor',
    Reproved: 'Reprovado',
    Approved: 'Aprovado',
    Finished: 'Finalizado',
    From: 'De',
    To: 'Até',
    'Opening balance': 'Saldo inicial',
    'Under analysis': 'Em análise',
    'Work order': 'Ordem de serviço',
    'New sector': 'Novo setor',
    'New service': 'Nova serviço',
    'New work order': 'Nova ordem de serviço',
    'New work history': 'Novo histórico de ordem de serviço',
    'New cash flow entry': 'Nova entrada no fluxo de caixa',
    'New cash': 'Novo caixa',
    'Unit price': 'Preço unitário',
    'Total price': 'Preço total',
    Quantity: 'Quantidade',
    Revenue: 'Receita',
    Expense: 'Despesa',
    'Revenue edit': 'Edição de receita',
    'Expense edit': 'Edição de despesa',
    'Add revenue': 'Adicionar receita',
    'Add expense': 'Adicionar despesa',
    Pending: 'Pendente',
    Canceled: 'Cancelado',
    Paid: 'Pago',
    Overdue: 'Atrasado',
    'New history entry': 'Nova entrada de histórico',
    'Are you sure you want to delete the work order "{key}"?': 'Tem certeza que deseja excluir a ordem de serviço "{key}"?', // eslint-disable-line prettier/prettier
    'Are you sure you want to delete the service "{key}"?': 'Tem certeza que deseja excluir o serviço "{key}"?',
    'Are you sure you want to delete the sector "{key}"?': 'Tem certeza que deseja excluir o setor "{key}"?',
    'Are you sure you want to delete the cash "{key}"?': 'Tem certeza que deseja excluir o caixa "{key}"?',
    'Are you sure you want to delete the cash flow item "{key}"': 'Tem certeza que deseja excluir o item do fluxo de caixa "{key}"?', // eslint-disable-line prettier/prettier
  }
}
