import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-finances/shared/constants'
import { SectorCreateDTO } from 'plugin-finances/shared/dto/SectorCreateDTO'
import { SectorResponseDTO } from 'plugin-finances/shared/dto/SectorResponseDTO'
import { SectorUpdateDTO } from 'plugin-finances/shared/dto/SectorUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.SECTORS}`

export async function findSector(id: string): Promise<SectorResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function listSectors(params?: ListSectorsFilters): Promise<PaginationDTO<SectorResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createSector(data: SectorCreateDTO): Promise<SectorResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateSector(id: string, data: SectorUpdateDTO): Promise<SectorResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteSector(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
