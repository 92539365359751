import { Button, Container } from 'react-bootstrap'

import styled from 'styled-components'

export const AddCashflowItemButton = styled(Button).attrs({ type: 'button' })`
  margin: calc(var(--spacing-3) * -1);
  width: calc(100% + var(--spacing-3) * 2);
  background: unset;
  border-color: var(--gray-800);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 42px;

  &:hover {
    &.revenue {
      background: var(--green-500);
      border-color: var(--green-500);
    }

    &.expense {
      background: var(--red-500);
      border-color: var(--red-500);
    }
  }
`

export const EditorFieldset = styled.fieldset`
  > .card.form-fields {
    > .card-body {
      display: grid;
      grid-template-columns: 1fr 200px 200px;
      grid-template-areas:
        'CLT CLT IDF'
        'ACC STS VAL'
        'DTS DTS DTS';
      gap: var(--spacing-2);

      > .form-select-container--client {
        grid-area: CLT;
      }

      > .form-select-container--account {
        grid-area: ACC;
      }

      > .form-input-container--identifier {
        grid-area: IDF;
      }

      > .form-input-container--value {
        grid-area: VAL;
      }

      > .form-input-container--status {
        grid-area: STS;
      }

      > .form-textarea-container--details {
        grid-area: DTS;
      }
    }
  }

  > .cashflow-items {
    margin-top: var(--spacing-2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-2);

    > .cashflow-items-revenues {
      > .sorted-table__wrapper {
        > table {
          > tbody {
            > tr {
              > td.table-column-service {
                > div {
                  > div {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .cashflow-items-expenses {
      > .sorted-table__wrapper {
        > table {
          > tbody {
            > tr {
              > td.table-column-service {
                > div {
                  > div {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const EditorContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    > .card.actions-footer {
      margin-top: var(--spacing-2);

      > .card-body {
        display: flex;
        justify-content: space-between;

        > .actions-area {
          display: flex;
          justify-content: center;
          align-items: center;

          > button {
            padding-top: 5px;
            padding-bottom: 5px;

            &.btn.btn-link {
              color: var(--gray-0);
              text-decoration: none;
            }
          }

          > span {
            margin-left: 10px;
          }
        }

        > .required-warn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--font-semibold);
          color: var(--red-500);
        }
      }
    }
  }
`
