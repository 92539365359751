import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'
import { selectByLanguage } from 'methone/utils/selectByLanguage'

import { CASHFLOW_PATHS, PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-finances/shared/constants'
import { CashflowCreateDTO } from 'plugin-finances/shared/dto/CashflowCreateDTO'
import { CashflowResponseDTO } from 'plugin-finances/shared/dto/CashflowResponseDTO'
import { CashflowUpdateDTO } from 'plugin-finances/shared/dto/CashflowUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.CASHFLOW}`

export async function exportCashflowList(fileType: 'csv' | 'excel' | 'pdf', params: ListClientsFilters): Promise<File> {
  const { data: response } = await api.get(`/${ENDPOINT}/${CASHFLOW_PATHS.EXPORT}/${fileType}`, {
    params,
    responseType: 'blob'
  })

  const fileName = selectByLanguage('fluxo-de-caixa', 'cash-flow')
  const exts = {
    csv: 'csv',
    pdf: 'pdf',
    excel: 'xlsx'
  }

  return new File([response], `${fileName}.${exts[fileType]}`, { type: response.type })
}

export async function listCashflow(params?: ListCashflowFilters): Promise<PaginationDTO<CashflowResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function findCashflow(id: string): Promise<CashflowResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function createCashflow(data: CashflowCreateDTO): Promise<CashflowResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateCashflow(id: string, data: CashflowUpdateDTO): Promise<CashflowResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteCashflow(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
