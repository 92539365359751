import React from 'react'
import { Badge, Button, Card, Dropdown } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormDatePicker, HookFormInput, HookFormSelect } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { dateService } from 'methone/services/dateService'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { findClientResume, listClientGroups } from 'methone/services/rest/clients'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'
import { validatePermsByStore } from 'methone/utils/validatePermsByStore'

import { findAccount, listAccounts } from 'plugin-finances/services/financeAccounts'
import { deleteCashflow, exportCashflowList, listCashflow } from 'plugin-finances/services/financeCashflow'
import { CashflowResumeResponseDTO } from 'plugin-finances/shared/dto/CashflowResumeResponseDTO'
import { FinancesPermissions } from 'plugin-finances/shared/permissions'
import { MENU_AREA, MENU_PATHS, cashflowStatus as cfStatus } from 'plugin-finances/utils/constants'
import { toOption } from 'plugin-finances/utils/toOption'

import { Form, SolidDot, ListContainer } from './styled'

type Omits = 'clientId' | 'accountId' | 'status' | 'groups' | 'fromDate' | 'toDate'
interface FormData extends Omit<ListCashflowFilters, Omits> {
  client: Option<string>
  groups: Options<string>
  account: Option<string>
  status: Options<string>
  fromDate: Date
  toDate: Date
}

const defaultParams: ListCashflowFilters = {
  page: 1,
  perPage: 8,
  orderBy: 'createdAt',
  direction: 'DESC'
}

const defaultValues: FormData = {
  client: null,
  groups: [],
  status: [],
  account: null,
  identifier: undefined,
  fromDate: undefined,
  toDate: undefined,
  value: undefined
}

const bodyCellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 'var(--spacing-1)'
}

async function accountsOptions(params: Parameters<typeof listAccounts>[0]): Promise<Options<string>> {
  return listAccounts(params).then(toOption)
}

export function CashflowPage(): JSX.Element {
  const [params, setParams] = useQueryParams<ListCashflowFilters>(defaultParams)
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues })
  const [clientGroups, setClientGroups] = React.useState<Options<string>>([])

  const { loading: accountsLoading, data: accounts, error: accountsError } = useRequest(accountsOptions)
  const { loading, mutate, run, data, error } = useRequest(listCashflow, { manual: true })
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()

  function handleDownloadFile(fileType: 'csv' | 'excel' | 'pdf') {
    return async () => {
      let url: string = null
      try {
        setIsLoading(true)
        const file = await exportCashflowList(fileType, params)
        url = URL.createObjectURL(file)

        const link = document.createElement('a')
        link.href = url
        link.download = file.name
        link.click()
      } catch (error) {
        handleHTTPRequestError(error)
      } finally {
        setIsLoading(false)

        if (url != null) {
          URL.revokeObjectURL(url)
        }
      }
    }
  }

  async function handleDelete({ id, identifier: key }: Partial<CashflowResumeResponseDTO>): Promise<void> {
    try {
      setIsLoading(true)

      if (await globalConfirmation.danger(i18n('Are you sure you want to delete the cash flow "{key}"?', { key }))) {
        await deleteCashflow(id)
        mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsLoading(false)
    }
  }

  function onSubmit(fields: FormData): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      identifier: fields.identifier,
      clientId: fields.client?.value,
      accountId: fields.account?.value,
      value: fields.value,
      status: fields.status?.map((status) => status.value),
      groups: fields.groups?.map((group) => group.value),
      fromDate: fields.fromDate ? fields.fromDate.getTime() : null,
      toDate: fields.toDate ? fields.toDate.getTime() : null
    })
  }

  async function updateFormData(): Promise<void> {
    try {
      const client = params.clientId ? await findClientResume(params.clientId) : null
      const account = params.accountId ? await findAccount(params.accountId) : null

      const localClientGroups = await listClientGroups()
      setClientGroups(localClientGroups)

      reset({
        ...params,
        client: client ? { value: client.id, label: `${client.name} (${client.taxId})` } : null,
        groups: toOption(params.groups ?? []),
        status: cfStatus.filter((i) => (params.status ?? [])?.includes(i.value)),
        fromDate: params.fromDate ? dateService.toDate(params.fromDate) : null,
        toDate: params.toDate ? dateService.toDate(params.toDate) : null,
        account: account ? { value: account.id, label: account.name } : null
      })
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }

    if (accountsError) {
      handleHTTPRequestError(accountsError)
    }
  }, [accountsError, error])

  React.useEffect(() => {
    run(params)
    updateFormData()
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ListContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <ClientSelectorHookForm control={control} label={i18n('Client')} name="client" />
              <HookFormSelect control={control} label={i18n('Groups')} name="groups" isMulti options={clientGroups} />
            </div>

            <div className="form-row">
              <HookFormSelect control={control} label={i18n('Cash')} name="account" isMulti options={accounts} />
              <HookFormInput control={control} label={i18n('Identifier')} name="identifier" />
              <HookFormSelect control={control} label={i18n('Status')} name="status" isMulti options={cfStatus} />
              <HookFormInput control={control} label={i18n('Value')} inputProps={{ type: 'number' }} name="value" />
              <HookFormDatePicker control={control} label={i18n('From')} name="fromDate" />
              <HookFormDatePicker control={control} label={i18n('To')} name="toDate" />
            </div>

            <div className="actions">
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary" className="export-button">
                  <i className="fas fa-download" />
                  {i18n('Export')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleDownloadFile('csv')}>
                    <i className="fas fa-file-csv" />
                    {i18n('CSV')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadFile('excel')}>
                    <i className="fas fa-file-excel" />
                    {i18n('Excel')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadFile('pdf')}>
                    <i className="fas fa-file-pdf" />
                    {i18n('PDF')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.CASHFLOW}/new`)}
              >
                <i className="fas fa-plus" />
                {i18n('New cash flow entry')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <SortedTable
        items={(data?.entries ?? []).map(({ client, ...cf }) => ({
          id: cf.id,
          status: <SolidDot title={cfStatus.find((status) => status.value === cf.status)?.label} status={cf.status} />,
          client: (
            <div>
              {client.group && (
                <Badge bg="outline-secondary" style={{ marginRight: 'var(--spacing-2)' }}>
                  {client.group}
                </Badge>
              )}
              {client.name}
            </div>
          ),
          identifier: cf.identifier,
          value: cf.value ?? '---',
          createdAt: dateService.formatDateTime(cf.createdAt)
        }))}
        columns={[
          { bodyCellStyle, title: '', columnRef: 'status', sortable: false, width: 45 },
          { bodyCellStyle, title: i18n('Client'), columnRef: 'client', sortable: true },
          { bodyCellStyle, title: i18n('Identifier'), columnRef: 'identifier', sortable: true, width: 150 },
          { bodyCellStyle, title: i18n('Created at'), columnRef: 'createdAt', sortable: true, width: 160 }
        ]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/${MENU_AREA}/${MENU_PATHS.CASHFLOW}/${id}`)
          },
          {
            icon: 'far fa-trash-alt',
            onClick: handleDelete,
            showOn: 'desktop',
            hide: !validatePermsByStore([FinancesPermissions.FINANCES_ADMIN])
          }
        ]}
      />
      <div style={{ paddingTop: 'var(--spacing-2)' }}>
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={loading || isLoading || accountsLoading} />
    </ListContainer>
  )
}
