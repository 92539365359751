import { i18n } from 'methone/services/i18n'

import { CASHFLOW_STATUS } from 'plugin-finances/shared/constants'

export const MENU_AREA = 'finances'

export const MENU_PATHS = {
  SECTORS: 'sectors',
  SERVICES: 'services',
  WORK_ORDERS: 'work-orders',
  CASH: 'cash',
  CASHFLOW: 'cashflow'
}

export const workOrderStatus = [
  {
    value: 'UNDER_ANALYSIS',
    get label() {
      return i18n('Under analysis')
    }
  },
  {
    value: 'REPROVED',
    get label() {
      return i18n('Reproved')
    }
  },
  {
    value: 'APPROVED',
    get label() {
      return i18n('Approved')
    }
  },
  {
    value: 'FINISHED',
    get label() {
      return i18n('Finished')
    }
  }
]

export const cashflowStatus: Options<CASHFLOW_STATUS> = [
  {
    value: CASHFLOW_STATUS.PENDING,
    get label() {
      return i18n('Pending')
    }
  },
  {
    value: CASHFLOW_STATUS.CANCELED,
    get label() {
      return i18n('Canceled')
    }
  },
  {
    value: CASHFLOW_STATUS.PAID,
    get label() {
      return i18n('Paid')
    }
  },
  {
    value: CASHFLOW_STATUS.OVERDUE,
    get label() {
      return i18n('Overdue')
    }
  }
]
