import { Container } from 'react-bootstrap'

import styled from 'styled-components'

import { CASHFLOW_STATUS } from 'plugin-finances/shared/constants'

function handleStatusColor(status: CASHFLOW_STATUS): string {
  switch (status) {
    case CASHFLOW_STATUS.PENDING:
      return 'var(--yellow-500)'
    case CASHFLOW_STATUS.CANCELED:
      return 'var(--red-500)'
    case CASHFLOW_STATUS.OVERDUE:
      return 'var(--blue-500)'
    case CASHFLOW_STATUS.PAID:
      return 'var(--green-500)'
    default:
      return 'var(--gray-300)'
  }
}

export const SolidDot = styled.div<{ status: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ status }) => handleStatusColor(status)};
`

export const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas:
    'RW1'
    'RW2'
    'ACT';
  grid-template-columns: 1fr;
  gap: var(--spacing-2);

  > div.form-row:nth-child(1) {
    grid-area: RW1;
    display: grid;
    grid-template-areas: 'CLT CGP';
    grid-template-columns: 1fr 200px;
    gap: var(--spacing-2);

    > .form-select-container--client {
      grid-area: CLT;
    }
  }

  > div.form-row:nth-child(2) {
    grid-area: RW2;
    display: grid;
    grid-template-areas: 'CSH IDF STS VLU FDT TDT';
    grid-template-columns: 200px 1fr 1fr 1fr 100px 100px;
    gap: var(--spacing-2);

    > .form-select-container--account {
      grid-area: CSH;
    }

    > .form-select-container--identifier {
      grid-area: IDF;
    }

    > .form-select-container--status {
      grid-area: STS;
    }

    > .form-select-container--value {
      grid-area: VLU;
    }

    > .form-select-container--fromDate {
      grid-area: FDT;
    }

    > .form-select-container--toDate {
      grid-area: TDT;
    }
  }

  > .actions {
    grid-area: ACT;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      gap: var(--spacing-2);
    }

    > div.dropdown {
      margin-right: auto;

      > button.export-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-2);

        &:hover,
        &.show {
          i {
            color: var(--gray-0);
          }
        }
      }
    }
  }
`

export const ListContainer = styled(Container)`
  padding: var(--spacing-4);

  .card {
    .card-body {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-2);

      > button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        gap: var(--spacing-2);

        > .badge {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }
  }

  > div {
    > table {
      .table-column-client {
        > div {
          display: flex;
          gap: var(--spacing-2);

          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;

            &.badge {
              height: 21px;
            }
          }
        }
      }
    }
  }
`
