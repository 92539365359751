import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const EditorFieldset = styled.fieldset`
  > .card.form-fields {
    > .card-body {
      display: grid;
      grid-template-columns: 1fr 200px;
      grid-template-areas: 'NM SC' 'AC AC';
      gap: var(--spacing-2);
    }
  }
`

export const EditorContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    > .card.actions-footer {
      margin-top: var(--spacing-2);

      > .card-body {
        display: flex;
        justify-content: space-between;

        > .actions-area {
          display: flex;
          justify-content: center;
          align-items: center;

          > button {
            padding-top: 5px;
            padding-bottom: 5px;

            &.btn.btn-link {
              color: var(--gray-0);
              text-decoration: none;
            }
          }

          > span {
            margin-left: 10px;
          }
        }

        > .required-warn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--font-semibold);
          color: var(--red-500);
        }
      }
    }
  }
`
