import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormDatePicker, HookFormInput, HookFormSelect } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { dateService } from 'methone/services/dateService'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { findClientResume, listClientGroups } from 'methone/services/rest/clients'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'
import { validatePermsByStore } from 'methone/utils/validatePermsByStore'

import { listSectors } from 'plugin-finances/services/financeSectors'
import { listServices } from 'plugin-finances/services/financeServices'
import { deleteWorkOrder, listWorkOrders } from 'plugin-finances/services/financeWorkOrders'
import { WorkOrderResponseDTO } from 'plugin-finances/shared/dto/WorkOrderResponseDTO'
import { FinancesPermissions } from 'plugin-finances/shared/permissions'
import { MENU_AREA, MENU_PATHS, workOrderStatus as woStatus } from 'plugin-finances/utils/constants'
import { servicesToOption, toOption } from 'plugin-finances/utils/toOption'

import { Form, SolidDot, WorkOrdersContainer } from './styled'

type Omits = 'clientId' | 'services' | 'sectors' | 'status' | 'groups' | 'fromDate' | 'toDate'
interface FormData extends Omit<ListWorkOrdersFilters, Omits> {
  client: Option<string>
  services: Options<string>
  sectors: Options<string>
  status: Options<string>
  groups: Options<string>
  fromDate: Date
  toDate: Date
}

const defaultParams: ListWorkOrdersFilters = {
  page: 1,
  perPage: 8,
  orderBy: 'updatedAt',
  direction: 'DESC',
  status: woStatus.filter((s) => s.value !== 'FINISHED').map((s) => s.value)
}

const defaultValues: FormData = {
  client: null,
  groups: [],
  sectors: [],
  services: [],
  status: woStatus.filter((s) => s.value !== 'FINISHED'),
  identifier: '',
  fromDate: undefined,
  toDate: undefined
}

const bodyCellStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 'var(--spacing-1)'
}

export function WorkOrdersPage(): JSX.Element {
  const [params, setParams] = useQueryParams<ListWorkOrdersFilters>(defaultParams)
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues })
  const [clientGroups, setClientGroups] = React.useState<Options<string>>([])
  const [sectors, setSectors] = React.useState<Options<string>>([])
  const [services, setServices] = React.useState<GroupOfOptions<Option<string>>[]>([])

  const { loading, mutate, run, data, error } = useRequest(listWorkOrders, { manual: true })
  const [isDeleting, setIsDeleting] = React.useState(false)
  const navigate = useNavigate()

  async function handleDelete({ id, identifier: key }: Partial<WorkOrderResponseDTO>): Promise<void> {
    try {
      setIsDeleting(true)

      if (await globalConfirmation.danger(i18n('Are you sure you want to delete the work order "{key}"?', { key }))) {
        await deleteWorkOrder(id)
        mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsDeleting(false)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      identifier: fields.identifier,
      clientId: fields.client?.value,
      services: fields.services?.map((service) => service.value),
      sectors: fields.sectors?.map((sector) => sector.value),
      status: fields.status?.map((status) => status.value),
      groups: fields.groups?.map((group) => group.value),
      fromDate: fields.fromDate ? fields.fromDate.getTime() : null,
      toDate: fields.toDate ? fields.toDate.getTime() : null
    })
  }

  async function updateFormData(): Promise<void> {
    try {
      const client = params.clientId ? await findClientResume(params.clientId) : null

      const localSectors = await listSectors().then(toOption)
      setSectors(localSectors)

      const { entries: localServices } = await listServices()
      setServices(servicesToOption(localServices))

      const localClientGroups = await listClientGroups()
      setClientGroups(localClientGroups)

      reset({
        ...params,
        client: client ? { value: client.id, label: `${client.name} (${client.taxId})` } : null,
        groups: toOption(params.groups ?? []),
        sectors: params.sectors?.map((i) => localSectors.find((t) => t.value === i)) ?? [],
        services: params.services?.map((i) => toOption(localServices).find((t) => t.value === i)) ?? [],
        status: woStatus.filter((i) => (params.status ?? [])?.includes(i.value)),
        fromDate: params.fromDate ? dateService.toDate(params.fromDate) : null,
        toDate: params.toDate ? dateService.toDate(params.toDate) : null
      })
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error])

  React.useEffect(() => {
    run(params)
    updateFormData()
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WorkOrdersContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <ClientSelectorHookForm control={control} label={i18n('Client')} name="client" />
              <HookFormSelect control={control} label={i18n('Groups')} name="groups" isMulti options={clientGroups} />
            </div>

            <div className="form-row">
              <HookFormInput control={control} label={i18n('Identifier')} name="identifier" />
              <HookFormSelect control={control} label={i18n('Services')} name="services" isMulti options={services} />
              <HookFormSelect control={control} label={i18n('Sectors')} name="sectors" isMulti options={sectors} />
              <HookFormSelect control={control} label={i18n('Status')} name="status" isMulti options={woStatus} />
              <HookFormDatePicker control={control} label={i18n('From')} name="fromDate" />
              <HookFormDatePicker control={control} label={i18n('To')} name="toDate" />
            </div>

            <div className="actions">
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.WORK_ORDERS}/new`)}
              >
                <i className="fas fa-plus" />
                {i18n('New work order')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <SortedTable
        items={(data?.entries ?? []).map(({ client, service: { sector, ...service }, ...work }) => ({
          id: work.id,
          status: (
            <SolidDot title={woStatus.find((status) => status.value === work.status)?.label} status={work.status} />
          ),
          client: (
            <div>
              {client.group && (
                <Badge bg="outline-secondary" style={{ marginRight: 'var(--spacing-2)' }}>
                  {client.group}
                </Badge>
              )}
              {client.name}
            </div>
          ),
          sector: sector.name,
          service: service.name,
          identifier: work.identifier,
          value: work.value ?? '---',
          updatedAt: dateService.formatDateTime(work.updatedAt)
        }))}
        columns={[
          { bodyCellStyle, title: '', columnRef: 'status', sortable: false },
          { bodyCellStyle, title: i18n('Client'), columnRef: 'client', sortable: true },
          { bodyCellStyle, title: i18n('Identifier'), columnRef: 'identifier', sortable: true, width: 150 },
          { bodyCellStyle, title: i18n('Sector'), columnRef: 'sector', sortable: true, width: 100 },
          { bodyCellStyle, title: i18n('Service'), columnRef: 'service', sortable: true, width: 150 },
          { bodyCellStyle, title: i18n('Updated at'), columnRef: 'updatedAt', sortable: true, width: 160 }
        ]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/${MENU_AREA}/${MENU_PATHS.WORK_ORDERS}/${id}`)
          },
          {
            icon: 'far fa-trash-alt',
            onClick: handleDelete,
            showOn: 'desktop',
            hide: !validatePermsByStore([FinancesPermissions.FINANCES_ADMIN])
          }
        ]}
      />
      <div style={{ paddingTop: 'var(--spacing-2)' }}>
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={loading || isDeleting} />
    </WorkOrdersContainer>
  )
}
