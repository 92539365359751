import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { createRoot } from 'react-dom/client'
import { useForm } from 'react-hook-form'

import { useRequest } from 'ahooks'
import styled from 'styled-components'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { DEFAULT_PORTAL_ID } from 'methone/utils/constants'
import { getOrCreatePortalRoot } from 'methone/utils/getOrCreatePortalRoot'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { listServices } from 'plugin-finances/services/financeServices'
import { PLUGIN_NAME } from 'plugin-finances/shared/constants'

const PORTAL_ROOT_ID = `${DEFAULT_PORTAL_ID}--${PLUGIN_NAME}--cashflow-item-edit-modal`

interface Props {
  data: CashflowItemEditor
  onCancel: () => void
  onApply: (data: CashflowItemEditor) => void
}

const StyledModal = styled(Modal)`
  > .modal-dialog {
    > .modal-content {
      > .modal-body {
        > form {
          display: grid;
          grid-template-columns: 1fr 100px;
          grid-template-areas: 'SRV SRV' 'UPR QTY' 'ACT ACT';
          gap: var(--spacing-2);

          > div {
            &:nth-child(1) {
              grid-area: SRV;
            }

            &:nth-child(2) {
              grid-area: UPR;
            }

            &:nth-child(3) {
              grid-area: QTY;
            }

            &:nth-child(4) {
              grid-area: ACT;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
`

async function listServicesToOptionGroup(): Promise<GroupOfOptions<Option<string>>[]> {
  const { entries: services } = await listServices()

  return services.reduce((acc, { sector, ...service }) => {
    const group = acc.find((group) => group.label === sector.name)

    if (group) {
      group.options.push({ value: service.id, label: service.name })
    } else {
      acc.push({ label: sector.name, options: [{ value: service.id, label: service.name }] })
    }

    return acc
  }, [])
}

const EditModal: React.FC<Props> = ({ data, onApply, onCancel }) => {
  const type = data.type
  const { control, formState, handleSubmit, reset } = useForm<CashflowItemEditor>({ mode: 'all' })
  const { loading: servicesLoading, data: services, error: servicesError } = useRequest(listServicesToOptionGroup)

  function handleApply(data: CashflowItemEditor): void {
    onApply(data)
  }

  React.useEffect(() => {
    if (data != null) {
      reset(data)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (servicesError) {
      handleHTTPRequestError(servicesError)
    }
  }, [servicesError])

  return (
    <>
      <StyledModal show={true} size="sm" onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{i18n(`${type.charAt(0) + type.toLowerCase().slice(1)} edit`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleApply)}>
            <HookFormSelect
              control={control}
              rules={{ required: true }}
              name="service"
              label={i18n('Service')}
              options={services}
            />
            <HookFormInput
              control={control}
              rules={{ required: true }}
              name="unitPrice"
              label={i18n('Unit price')}
              inputProps={{ type: 'number' }}
            />
            <HookFormInput
              control={control}
              rules={{ required: true }}
              name="quantity"
              label={i18n('Quantity')}
              inputProps={{ type: 'number' }}
            />
            <div className="footer">
              <Button type="submit" variant="primary" disabled={formState.isValid !== true}>
                {i18n('Apply')}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </StyledModal>
      <FixedLoading enabled={servicesLoading} />
    </>
  )
}

interface FinalProps {
  data: CashflowItemEditor
  onApply: (data: CashflowItemEditor) => void
}

export function triggerCashflowItemEditModal(props: FinalProps): void {
  const container = getOrCreatePortalRoot(PORTAL_ROOT_ID)
  const portalRoot = createRoot(container)

  function handleClose(data?: CashflowItemEditor): void {
    data && props.onApply(data)
    portalRoot.unmount()
  }

  portalRoot.render(<EditModal data={props.data} onCancel={handleClose} onApply={handleClose} />)
}
