import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import * as accountsServices from 'plugin-finances/services/financeAccounts'
import { AccountResponseDTO } from 'plugin-finances/shared/dto/AccountResponseDTO'
import { AccountUpdateDTO } from 'plugin-finances/shared/dto/AccountUpdateDTO'
import { MENU_AREA, MENU_PATHS } from 'plugin-finances/utils/constants'

import { EditorContainer, EditorFieldset } from './styled'

type FormProps = Pick<AccountResponseDTO, 'id' | 'name' | 'openingBalance'>

const defaultValues: FormProps = {
  id: '',
  name: '',
  openingBalance: 0
}

export function AccountsEditorPage(): JSX.Element {
  const { id } = useParams()
  const [savingState, setSavingState] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')

  const { control, handleSubmit, reset } = useForm<FormProps>({ defaultValues, mode: 'all' })
  const { loading, run, data, error } = useRequest(accountsServices.findAccount, { manual: true })

  const navigate = useNavigate()

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setSavingState('saving')

      const bodyData: AccountUpdateDTO = { name: data.name, openingBalance: Number(data.openingBalance) }

      if (id === 'new') {
        const createdUser = await accountsServices.createAccount(bodyData)
        navigate(`/${MENU_AREA}/${MENU_PATHS.CASH}/${createdUser.id}`)
      } else {
        await accountsServices.updateAccount(id, bodyData)
        run(id)
      }
      setSavingState('saved')
    } catch (e) {
      setSavingState('error')
      handleHTTPRequestError(e)
    }
  }

  React.useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (id && id !== 'new') {
      run(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FixedLoading position="absolute" enabled={loading || savingState === 'saving'} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card className="form-fields">
              <Card.Body>
                <HookFormInput control={control} rules={{ required: true }} name="name" label={i18n('Name')} />
                <HookFormInput
                  control={control}
                  rules={{ required: true }}
                  name="openingBalance"
                  label={i18n('Opening balance')}
                  inputProps={{ type: 'number' }}
                />
              </Card.Body>
            </Card>
          </EditorFieldset>

          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <SaveButton
                  type="submit"
                  status={savingState}
                  customIdleContent={i18n(id === 'new' ? 'Create' : 'Save')}
                />
                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.CASH}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
