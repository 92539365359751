export const PLUGIN_NAME = 'plugin-finances'

export enum REST_ENDPOINTS {
  SECTORS = 'sectors',
  SERVICES = 'services',
  WORK_ORDERS = 'work-orders',
  ACCOUNTS = 'accounts',
  CASHFLOW = 'cashflow'
}

export enum CASHFLOW_PATHS {
  EXPORT = 'export'
}

export enum WORK_ORDERS_PATHS {
  HISTORY = 'history'
}

export enum CASHFLOW_STATUS {
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE'
}

export enum CASHFLOW_ITEM_TYPE {
  REVENUE = 'REVENUE',
  EXPENSE = 'EXPENSE'
}
