import { i18n } from 'methone/services/i18n'
import { menuService } from 'methone/services/menuService'
import { routerService } from 'methone/services/routerService'

import { FinancesPermissions } from 'plugin-finances/shared/permissions'

import { enUS, ptBR } from './i18n'
import { AccountsPage, AccountsEditorPage } from './pages/FinanceAccounts'
import { CashflowEditorPage, CashflowPage } from './pages/FinanceCashflow'
import { SectorsEditorPage, SectorsPage } from './pages/FinanceSectors'
import { ServicesEditorPage, ServicesPage } from './pages/FinanceServices'
import { WorkOrdersEditorPage, WorkOrdersPage } from './pages/FinanceWorkOrders'
import { MENU_AREA, MENU_PATHS } from './utils/constants'

i18n.addItems('pt-BR', ptBR.values)
i18n.addItems('en-US', enUS.values)

const permissions = [FinancesPermissions.FINANCES_MANAGER, FinancesPermissions.FINANCES_ADMIN]
menuService.addItem({ basePath: `/${MENU_AREA}`, title: i18n('Finance'), icon: 'fas fa-dollar', permissions, order: Number.MAX_SAFE_INTEGER - 2 }) // eslint-disable-line prettier/prettier

;(function () {
  const permissions = [FinancesPermissions.FINANCES_ADMIN]

  menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.CASH}`, title: i18n('Cash'), order: 3, element: AccountsPage }) // eslint-disable-line prettier/prettier
  routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.CASH}/:id`, title: i18n('Cash'), element: AccountsEditorPage, permissions }) // eslint-disable-line prettier/prettier

  menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.SECTORS}`, title: i18n('Sectors'), order: 4, element: SectorsPage, permissions }) // eslint-disable-line prettier/prettier
  routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.SECTORS}/:id`, title: i18n('Sectors'), element: SectorsEditorPage, permissions }) // eslint-disable-line prettier/prettier

  menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.SERVICES}`, title: i18n('Services'), order: 5, element: ServicesPage, permissions }) // eslint-disable-line prettier/prettier
  routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.SERVICES}/:id`, title: i18n('Services'), element: ServicesEditorPage, permissions }) // eslint-disable-line prettier/prettier
})()
;(function () {
  menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.WORK_ORDERS}`, title: i18n('Work orders'), order: 1, element: WorkOrdersPage }) // eslint-disable-line prettier/prettier
  routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.WORK_ORDERS}/:id`, title: i18n('Work orders'), element: WorkOrdersEditorPage, permissions }) // eslint-disable-line prettier/prettier

  menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.CASHFLOW}`, title: i18n('Cash flow'), order: 2, element: CashflowPage }) // eslint-disable-line prettier/prettier
  routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.CASHFLOW}/:id`, title: i18n('Cash flow'), element: CashflowEditorPage, permissions }) // eslint-disable-line prettier/prettier
})()
