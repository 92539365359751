import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-finances/shared/constants'
import { ServiceCreateDTO } from 'plugin-finances/shared/dto/ServiceCreateDTO'
import { ServiceResponseDTO } from 'plugin-finances/shared/dto/ServiceResponseDTO'
import { ServiceUpdateDTO } from 'plugin-finances/shared/dto/ServiceUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.SERVICES}`

export async function findService(id: string): Promise<ServiceResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function listServices(params?: ListServicesFilters): Promise<PaginationDTO<ServiceResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createService(data: ServiceCreateDTO): Promise<ServiceResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateService(id: string, data: ServiceUpdateDTO): Promise<ServiceResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteService(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
