import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { deleteService, listServices } from 'plugin-finances/services/financeServices'
import { ServiceResponseDTO } from 'plugin-finances/shared/dto/ServiceResponseDTO'
import { MENU_AREA, MENU_PATHS } from 'plugin-finances/utils/constants'

import { Form, ServicesContainer } from './styled'

type FormData = ListServicesFilters

const defaultValues: FormData = {
  page: 1,
  perPage: 10,
  name: ''
}

export function ServicesPage(): JSX.Element {
  const [params, setParams] = useQueryParams<ListServicesFilters>({ page: 1, perPage: 8 })
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues })

  const { loading, mutate, run, data, error } = useRequest(listServices, { manual: true })
  const [isDeleting, setIsDeleting] = React.useState(false)
  const navigate = useNavigate()

  async function handleDelete({ id, name: key }: Partial<ServiceResponseDTO>): Promise<void> {
    try {
      setIsDeleting(true)

      if (await globalConfirmation.danger(i18n('Are you sure you want to delete the sector "{key}"?', { key }))) {
        await deleteService(id)
        mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsDeleting(false)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      name: fields.name
    })
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error])

  React.useEffect(() => {
    run(params)
    reset(params)
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ServicesContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <HookFormInput control={control} label={i18n('Name')} name="name" />
            <div className="actions">
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.SERVICES}/new`)}
              >
                <i className="fas fa-plus" />
                {i18n('New service')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <SortedTable
        items={(data?.entries ?? []).map((type) => ({ id: type.id, name: type.name }))}
        columns={[{ title: i18n('Name'), columnRef: 'name', sortable: true }]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/${MENU_AREA}/${MENU_PATHS.SERVICES}/${id}`)
          },
          {
            icon: 'far fa-trash-alt',
            onClick: handleDelete,
            showOn: 'desktop'
          }
        ]}
      />
      <div style={{ paddingTop: 'var(--spacing-2)' }}>
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={loading || isDeleting} />
    </ServicesContainer>
  )
}
