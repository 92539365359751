import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'

import { ServiceResponseDTO } from 'plugin-finances/shared/dto/ServiceResponseDTO'

type BasicEntity = { id: string; name: string }

function paginationGuard(item: any): item is PaginationDTO<BasicEntity> {
  return !Array.isArray(item) && 'entries' in item
}

function stringNumberArrayGuard(item: any): item is string[] {
  return Array.isArray(item) && item.every((i) => typeof i === 'string')
}

export function toOption(item: string[]): Options
export function toOption(item: BasicEntity[]): Options
export function toOption(item: PaginationDTO<BasicEntity>): Options
export function toOption(item: BasicEntity[] | PaginationDTO<BasicEntity> | string[]): Options {
  if (item == null) {
    return []
  }

  if (paginationGuard(item)) {
    if (item.entries == null || item.entries.length === 0) {
      return []
    }

    return item.entries.map((e) => ({ value: e.id, label: e.name })).sort((a, b) => a.label.localeCompare(b.label))
  }

  if (stringNumberArrayGuard(item)) {
    return item.map((e) => ({ value: e, label: e })).sort((a, b) => a.label.localeCompare(b.label))
  }

  return item.map((e) => ({ value: e.id, label: e.name })).sort((a, b) => a.label.localeCompare(b.label))
}

export function servicesToOption(services: ServiceResponseDTO[]): GroupOfOptions<Option<string>>[] {
  if (services == null || services == null) {
    return []
  }

  const valuesObject = services.reduce((acc, curr) => {
    if (!(curr.sector.name in acc)) {
      acc[curr.sector.name] = {
        label: curr.sector.name,
        options: []
      }
    }

    acc[curr.sector.name].options.push({ label: curr.name, value: curr.id })
    acc[curr.sector.name].options.sort((a, b) => a.label.localeCompare(b.label))

    return acc
  }, {} as Record<string, GroupOfOptions<Option<string>>>)

  return Object.values(valuesObject)
}
