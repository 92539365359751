import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-finances/shared/constants'
import { AccountCreateDTO } from 'plugin-finances/shared/dto/AccountCreateDTO'
import { AccountResponseDTO } from 'plugin-finances/shared/dto/AccountResponseDTO'
import { AccountResumeResponseDTO } from 'plugin-finances/shared/dto/AccountResumeResponseDTO'
import { AccountUpdateDTO } from 'plugin-finances/shared/dto/AccountUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.ACCOUNTS}`

export async function findAccount(id: string): Promise<AccountResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

// eslint-disable-next-line prettier/prettier
export async function listAccounts(params?: ListAccountsFilters): Promise<PaginationDTO<AccountResumeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createAccount(data: AccountCreateDTO): Promise<AccountResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

// eslint-disable-next-line prettier/prettier
export async function updateAccount(id: string, data: AccountUpdateDTO): Promise<AccountResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteAccount(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
