import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { listSectors } from 'plugin-finances/services/financeSectors'
import { createService, findService, updateService } from 'plugin-finances/services/financeServices'
import { ServiceResponseDTO } from 'plugin-finances/shared/dto/ServiceResponseDTO'
import { ServiceUpdateDTO } from 'plugin-finances/shared/dto/ServiceUpdateDTO'
import { MENU_AREA, MENU_PATHS } from 'plugin-finances/utils/constants'
import { toOption } from 'plugin-finances/utils/toOption'

import { EditorContainer, EditorFieldset } from './styled'

interface FormProps extends Pick<ServiceResponseDTO, 'id' | 'name'> {
  sector: Option<string>
}

const defaultValues: FormProps = {
  id: '',
  sector: null,
  name: ''
}

export function ServicesEditorPage(): JSX.Element {
  const { id } = useParams()

  const { control, handleSubmit, reset } = useForm<FormProps>({ defaultValues, mode: 'all' })

  const [isLoading, setIsLoading] = React.useState(true)

  const { loading, data: sectors, error } = useRequest(listSectors)
  const navigate = useNavigate()

  async function handleGetVehicleData(): Promise<void> {
    try {
      setIsLoading(true)

      if (id === 'new') {
        reset(defaultValues)
      } else {
        const response = await findService(id)
        reset({
          ...response,
          sector: response.sector ? { value: response.sector.id, label: response.sector.name } : null
        })
      }

      setIsLoading(false)
    } catch (e) {
      handleHTTPRequestError(e)
    }
  }

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setIsLoading(true)

      const bodyData: ServiceUpdateDTO = { name: data.name, sectorId: data.sector.value }

      if (id === 'new') {
        const createdUser = await createService(bodyData)
        navigate(`/${MENU_AREA}/${MENU_PATHS.SERVICES}/${createdUser.id}`)
      } else {
        await updateService(id, bodyData)
        await handleGetVehicleData()
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (id) {
      handleGetVehicleData()
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FixedLoading position="absolute" enabled={isLoading || loading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card className="form-fields">
              <Card.Body>
                <HookFormInput control={control} rules={{ required: true }} name="name" label={i18n('Name')} />
                <HookFormSelect
                  control={control}
                  rules={{ required: true }}
                  name="sector"
                  label={i18n('Sector')}
                  options={toOption(sectors)}
                />
              </Card.Body>
            </Card>
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <Button type="submit" variant="primary">
                  {i18n(id === 'new' ? 'Create' : 'Save')}
                </Button>
                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.SERVICES}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
