import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect, HookFormTextarea } from 'methone/components/ui/Forms'
import { SortedTable } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { findClient } from 'methone/services/rest/clients'
import { Numbers } from 'methone/shared/Numbers'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { listAccounts } from 'plugin-finances/services/financeAccounts'
import { createCashflow, findCashflow, updateCashflow } from 'plugin-finances/services/financeCashflow'
import { findService } from 'plugin-finances/services/financeServices'
import { CASHFLOW_ITEM_TYPE } from 'plugin-finances/shared/constants'
import { CashflowResponseDTO } from 'plugin-finances/shared/dto/CashflowResponseDTO'
import { CashflowUpdateDTO } from 'plugin-finances/shared/dto/CashflowUpdateDTO'
import { MENU_AREA, MENU_PATHS, cashflowStatus } from 'plugin-finances/utils/constants'
import { toOption } from 'plugin-finances/utils/toOption'
import { triggerCashflowItemEditModal } from 'plugin-finances/utils/triggerCashflowItemEditModal'

import { AddCashflowItemButton, EditorContainer, EditorFieldset } from './styled'

// eslint-disable-next-line prettier/prettier
type Omits = 'clientId' | 'accountId' | 'value' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' |'items' | 'client' | 'account'
interface FormProps extends Omit<CashflowResponseDTO, Omits> {
  client: Option<string>
  account: Option<string>
  value: string
  status: Option<string>
  items: CashflowItemEditor[]
}

const defaultValues: FormProps = {
  id: '',
  client: null,
  account: null,
  identifier: undefined,
  value: '',
  status: null,
  details: '',
  items: []
}

interface QueryParams {
  clientId: string
  details: string
  serviceId: string
  value: string
}

export function CashflowEditorPage(): JSX.Element {
  const { id } = useParams()
  const [queryParams] = useQueryParams<QueryParams>()
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormProps>({ defaultValues, mode: 'all' }) // eslint-disable-line prettier/prettier
  const { append, remove, update } = useFieldArray({ control, name: 'items', keyName: 'uid' })
  const cashflowItems = watch('items')

  const [savingStatus, setSavingStatus] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')
  const { loading, run, data: cashflow, error } = useRequest(findCashflow, { manual: true })
  const { loading: accountsLoading, data: accounts, error: accountsError } = useRequest(listAccounts)
  const navigate = useNavigate()

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setSavingStatus('saving')

      const bodyData: CashflowUpdateDTO = {
        accountId: data.account?.value,
        clientId: data.client?.value,
        status: data.status?.value,
        value: Numbers.parseFloat(data.value),
        details: data.details,
        items: data.items.map((item) => ({
          ...item,
          quantity: Numbers.parseInt(item.quantity),
          unitPrice: Numbers.parseFloat(item.unitPrice),
          serviceId: item.service.value,
          service: undefined
        }))
      }

      if (id === 'new') {
        const createdUser = await createCashflow(bodyData)
        navigate(`/${MENU_AREA}/${MENU_PATHS.CASHFLOW}/${createdUser.id}`)
      } else {
        await updateCashflow(id, bodyData)
        run(id)
      }

      setSavingStatus('saved')
    } catch (e) {
      setSavingStatus('error')
      handleHTTPRequestError(e)
    }
  }

  async function handleRemoveCashflowItem(item: { idx: number; service: string }): Promise<void> {
    const message = 'Are you sure you want to delete the cash flow item "{key}"'
    if (await globalConfirmation.warn(i18n(message, { key: item.service ?? item.idx }))) {
      remove(item.idx)
    }
  }

  function dispatchModal({ idx, type }: { idx: number; type: CASHFLOW_ITEM_TYPE }): void {
    triggerCashflowItemEditModal({
      data: cashflowItems[idx],
      onApply: (data) => update(idx, { ...data, cashflowId: id === 'new' ? undefined : id, type })
    })
  }

  function addCashflowItem(newItemType: CASHFLOW_ITEM_TYPE) {
    return (): void => {
      const defaultData = {
        id: undefined,
        cashflowId: id === 'new' ? undefined : id,
        service: null,
        type: newItemType,
        quantity: '1',
        unitPrice: undefined,
        createdAt: undefined,
        createdBy: undefined,
        updatedAt: undefined,
        updatedBy: undefined
      }

      triggerCashflowItemEditModal({
        data: defaultData,
        onApply: (data) => append({ ...data, cashflowId: id === 'new' ? undefined : id, type: newItemType })
      })
    }
  }

  React.useEffect(() => {
    if (cashflow) {
      reset({
        ...cashflow,
        client: { value: cashflow.client.id, label: cashflow.client.name },
        account: { value: cashflow.account.id, label: cashflow.account.name },
        value: Numbers.formatString(cashflow.value),
        status: cashflowStatus.find((status) => status.value === cashflow.status),
        items: cashflow.items.map(({ service, ...item }) => ({
          id: item.id,
          cashflowId: cashflow.id,
          type: item.type,
          quantity: Numbers.formatString(item.quantity),
          unitPrice: Numbers.formatString(item.unitPrice),
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          updatedAt: item.updatedAt,
          updatedBy: item.updatedBy,
          service: { value: service.id, label: service.name }
        }))
      })
    }
  }, [cashflow]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (queryParams != null && Object.keys(queryParams).length > 0) {
      ;(async function () {
        const client = await findClient(queryParams.clientId)
        const dbService = await findService(queryParams.serviceId)

        reset({
          client: { value: client.id, label: client.name },
          account: null,
          status: null,
          details: queryParams.details,
          items: [
            {
              type: CASHFLOW_ITEM_TYPE.REVENUE,
              service: { value: dbService.id, label: dbService.name },
              quantity: '1',
              unitPrice: queryParams.value
            }
          ]
        })
      })()
    }
  }, [queryParams]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (cashflowItems != null) {
      const value = cashflowItems.reduce((acc, item) => {
        const totalPrice = Numbers.parseInt(item.unitPrice ?? 0) * Numbers.parseFloat(item.quantity ?? 0)

        return acc + (item.type === CASHFLOW_ITEM_TYPE.REVENUE ? totalPrice : -totalPrice)
      }, 0)

      setValue('value', Numbers.formatString(value))
    }
  }, [cashflowItems]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    reset(defaultValues)
    if (id !== 'new') {
      run(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (accountsError) {
      handleHTTPRequestError(accountsError)
    }

    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error, accountsError])

  return (
    <>
      <FixedLoading position="absolute" enabled={savingStatus === 'saving' || loading || accountsLoading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card className="form-fields">
              <Card.Body>
                <ClientSelectorHookForm
                  control={control}
                  name="client"
                  rules={{ required: true }}
                  label={i18n('Client')}
                />
                <HookFormSelect
                  control={control}
                  rules={{ required: true }}
                  name="account"
                  label={i18n('Account')}
                  options={toOption(accounts)}
                />
                <HookFormInput
                  control={control}
                  name="identifier"
                  label={i18n('Identifier')}
                  inputProps={{ disabled: true }}
                />
                <HookFormInput
                  control={control}
                  name="value"
                  label={i18n('Total price')}
                  inputProps={{ type: 'number', disabled: true }}
                />
                <HookFormSelect
                  control={control}
                  rules={{ required: true }}
                  name="status"
                  label={i18n('Status')}
                  options={cashflowStatus}
                />
                <HookFormTextarea control={control} name="details" label={i18n('Details')} />
              </Card.Body>
            </Card>

            <div className="cashflow-items">
              <div className="cashflow-items-revenues">
                <SortedTable
                  items={cashflowItems
                    .map((item, i) => ({
                      idx: i,
                      type: item.type as CASHFLOW_ITEM_TYPE,
                      service: item.service?.label,
                      quantity: Numbers.parseInt(item.quantity),
                      value: Numbers.parseFloat(item.unitPrice)
                    }))
                    .filter((item) => item.type === CASHFLOW_ITEM_TYPE.REVENUE)}
                  columns={[
                    { columnRef: 'service', title: i18n('Revenue'), sortable: false },
                    { columnRef: 'value', title: i18n('Unit price'), sortable: false, width: 150 },
                    { columnRef: 'quantity', title: i18n('Quantity'), sortable: false, width: 100 }
                  ]}
                  actions={[
                    { icon: 'far fa-edit', onClick: dispatchModal },
                    { icon: 'far fa-trash-alt', onClick: handleRemoveCashflowItem }
                  ]}
                  noDataMessageShowAlways
                  noDataMessage={
                    <AddCashflowItemButton className="revenue" onClick={addCashflowItem(CASHFLOW_ITEM_TYPE.REVENUE)}>
                      {i18n('Add revenue')}
                    </AddCashflowItemButton>
                  }
                />
              </div>
              <div className="cashflow-items-expenses">
                <SortedTable
                  items={cashflowItems
                    .map((item, i) => ({
                      idx: i,
                      type: item.type as CASHFLOW_ITEM_TYPE,
                      service: item.service?.label,
                      quantity: Numbers.parseInt(item.quantity),
                      value: Numbers.parseFloat(item.unitPrice)
                    }))
                    .filter((item) => item.type === CASHFLOW_ITEM_TYPE.EXPENSE)}
                  columns={[
                    { columnRef: 'service', title: i18n('Expense'), sortable: false },
                    { columnRef: 'value', title: i18n('Unit price'), sortable: false, width: 150 },
                    { columnRef: 'quantity', title: i18n('Quantity'), sortable: false, width: 100 }
                  ]}
                  actions={[
                    { icon: 'far fa-edit', onClick: dispatchModal },
                    { icon: 'far fa-trash-alt', onClick: handleRemoveCashflowItem }
                  ]}
                  noDataMessageShowAlways
                  noDataMessage={
                    <AddCashflowItemButton className="expense" onClick={addCashflowItem(CASHFLOW_ITEM_TYPE.EXPENSE)}>
                      {i18n('Add expense')}
                    </AddCashflowItemButton>
                  }
                />
              </div>
            </div>
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <SaveButton
                  type="submit"
                  status={savingStatus}
                  customIdleContent={i18n(id === 'new' ? 'Create' : 'Save')}
                />

                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.CASHFLOW}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
